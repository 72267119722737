







































































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import LongTrendPeriodModule from "@/store/clients/LongTrendPeriod.module";
import VesselsModule from "@/store/clients/Vessels.module";
import ConsequencesClient from "@/Scripts/clients/consequences-client";
import { Consequence } from "@/types/consequence";
import { LongTrendPeriodMeta } from "@/types/longTrendPeriodMeta";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";

const LongTrendPeriod = getModule(LongTrendPeriodModule, store);
const Vessels = getModule(VesselsModule, store);

@Component
export default class ResultCard extends Vue {
  @Prop({ default: true }) loading!: boolean;
  _draft = 0;
  consequences: Consequence[] = [];
  consequencesLoading = true;
  isDraftFormValid = true;
  draftMenu = false;
  draftLoading = false;
  draftRules = {
    required: (value: number) => !!value || "Value required",
    valid: (value: number) => (value >= 2 && value <= 25) || "Value must be between 2 and 25",
  };

  @Watch("loading", { immediate: true })
  onLoaded(val: boolean): void {
    if (!val) {
      if (this.draft) {
        this.$data._draft = this.draft;
      }
      this.getConsequences();
    }
  }

  async handleUpdateDraft(): Promise<void> {
    if (this.meta) {
      this.draftLoading = true;
      const data = {
        vesselId: this.meta?.vesselId,
        draft: this.$data._draft,
      };
      await Vessels.updateVesselDraft(data);
      this.draftMenu = false;
      this.draftLoading = false;
      this.$emit("update");
    }
  }

  async getConsequences(): Promise<void> {
    await ConsequencesClient.find(Vessels.currentVessel?.id, this.longTrendPeriods?.longTrendId, this.startDate, this.endDate, this.longTrendPeriods?.benchmarkPeriod).then(data => {
      this.consequences = data;
      this.consequencesLoading = false;
    });
  }

  get hasConsequenceResults(): boolean {
    if (!this.consequences?.length) return false;
    return Boolean(this.consequences[0].results.length);
  }

  get meta(): LongTrendPeriodMeta | null {
    return LongTrendPeriod.meta;
  }

  get longTrendPeriods(): ILongTrendPeriod | null {
    return LongTrendPeriod.longTrendPeriod;
  }

  get isBenchmarking(): boolean | undefined {
    return this.longTrendPeriods?.isBenchmarking;
  }

  get draft(): number | null | undefined {
    return this.longTrendPeriods?.draft;
  }

  get showAsteriskText(): boolean {
    const type = this.diagnosticTypeName?.toLowerCase();
    return type === "speed" || type === "sfr";
  }
  get diagnosticTypeName(): string | undefined {
    return this.longTrendPeriods?.diagnosticTypeName;
  }

  get startDate(): string | undefined {
    return this.meta?.startDate;
  }

  get endDate(): string | undefined {
    return this.meta?.endDate;
  }

  get performanceStatusVSBenchmark(): number | string {
    if (!this.longTrendPeriods?.trendline || !this.longTrendPeriods.benchmarkPoint) return "";
    return this.round(this.longTrendPeriods.trendline.end.y - this.longTrendPeriods.benchmarkPoint.y);
  }

  get showPrediction(): boolean {
    if (!this.longTrendPeriods?.isCurrent) return false;
    if (this.showYellowPrediction || this.showRedPrediction) return true;
    return false;
  }

  get redLineReached(): string | null | undefined {
    return this.longTrendPeriods?.redLineReached;
  }

  get yellowLineReached(): string | null | undefined {
    return this.longTrendPeriods?.yellowLineReached;
  }

  get showYellowPrediction(): boolean {
    if (!this.redLineReached) return true;
    const redLineReached = new Date(this.redLineReached).getTime();
    if (redLineReached > Date.now()) return true;
    return false;
  }

  get showRedPrediction(): boolean {
    if (!this.yellowLineReached) return true;
    const yellowLineReached = new Date(this.yellowLineReached).getTime();
    if (yellowLineReached > Date.now() || yellowLineReached < new Date(this.startDate!).getTime()) return true;
    return false;
  }

  get yellowPrediction(): string {
    if (!this.yellowLineReached) return "Yellow zone will not be reached in the foreseeable future";
    const reachedDate = new Date(this.yellowLineReached).getTime();
    if (reachedDate < new Date(this.startDate!).getTime()) return "Yellow zone was reached before trend period start.";
    const verb = reachedDate < Date.now() ? "was" : "will be";
    return `Yellow zone ${verb} reached on ${this.$options.filters?.dateDMY(this.yellowLineReached)}.`;
  }

  get redPrediction(): string {
    if (!this.redLineReached) return "Red zone will not be reached in the foreseeable future";
    const reachedDate = new Date(this.redLineReached).getTime();
    if (reachedDate < new Date(this.startDate!).getTime()) return "Red zone was reached before trend period start.";
    const verb = reachedDate < Date.now() ? "was" : "will be";
    return `Red zone ${verb} reached on ${this.$options.filters?.dateDMY(this.redLineReached)}.`;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
